<template>
  <div>
    <a-card :border="false">
      <!-- <a-tabs default-active-key="1" size="small" type="card"> -->
        <!-- <a-tab-pane key="1" tab="配额申请" force-render> -->
          <div class="realtor">
            <!-- 查询表单 -->
            <!--      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="8" :xxl="8" :sm="12">
            <a-form-model-item label="经销商">
              <a-input v-model="searchData.name" placeholder="经销商名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="4" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>-->
            <div>
              <a-button v-if="isApply" type="primary" @click="toHandler('add')">申请临时配额</a-button>
              <a-button v-if="isAllocation && selectedRows.length == 1 && selectedRows[0].status == 2 && selectedRows[0].quotaStatus == 1" type="primary" @click="toHandler('store_allocation')">分配临时配额</a-button>
              <a-button v-if="isCheckAllocation && selectedRows.length == 1 && selectedRows[0].status == 2" type="primary" @click="toHandler('check_allocation')">查看分配记录</a-button>
              <!-- <a-button type="primary" @click="toHandler('del')">删除</a-button> -->
              <a-button
                v-if="isSubmit && selectedRows.length > 0 && selectedRows[0].status == 0"
                type="primary"
                @click="toHandler('submit_audio')"
                >提交审核</a-button
              >
              <a-button v-if="isAudit && selectedRows.length > 0 && selectedRows[0].status == 1" type="default" @click="toHandler('dealerRollbackStatus')" icon="rollback">撤审</a-button>
            </div>
            <!-- 数据表格 -->
            <a-table
              :loading="tableLoading"
              :row-selection="rowSelection"
              :rowKey="(record) => record.id"
              :columns="columns"
              :data-source="tableData"
              bordered
              :pagination="page"
              @change="changeTable"
              :customRow="changeTableRow"
            >
              <span slot="status" slot-scope="text">
                <span> {{ text | status }} </span>
              </span>
              <span slot="quotaStatus" slot-scope="text">
                <span v-if="text == 1">未过期</span>
                <span v-if="text == 2">已过期</span>
              </span>
              <span slot="expireDate" slot-scope="text,row">
                 <span v-if="row.startingTime != null && row.expireDate">{{row.startingTime}} 至 {{row.expireDate}}</span>
              </span>
              <span slot="useNum" slot-scope="text,row">
<!--                 <span>{{row.remainingNum + row.useNum + row.storeUseNum}}</span>-->
                 <span>{{row.useNum + row.storeUseNum}}</span>
              </span>
            </a-table>
            <!-- 申请配额 -->
            <DealerPreOrdersNum-edit-modal
              ref="DealerPreOrdersNumEditModal"
              @reload="getData"
            ></DealerPreOrdersNum-edit-modal>
            <!-- 分配配额 -->
            <AllocationStoreRuota ref="AllocationStoreRuota" @reload="getData"></AllocationStoreRuota>
            <!-- 查看分配记录 -->
            <AllocationRecord ref="AllocationRecord"></AllocationRecord>
          </div>
        <!-- </a-tab-pane> -->
        <!-- <a-tab-pane key="2" tab="配额详情" force-render>
          <a-table
            :rowKey="(record) => record.dealerId"
            :columns="columnsDeatil"
            :data-source="tableDeatilData"
            bordered
            :pagination="false"
            @change="changeTable"
          >
          </a-table>
        </a-tab-pane> -->
      <!-- </a-tabs> -->
    </a-card>
  </div>
</template>

<script>
import { columns } from './components/colums.js'
import DealerPreOrdersNumEditModal from './components/DealerPreOrdersNumEditModal.vue'
import AllocationStoreRuota from './components/AllocationStoreRuota.vue'
import AllocationRecord from './components/AllocationRecord.vue'

import {delDealerPreOrdersNum, listDealerPreOrdersNum, dealerRollbackStatus} from './api/DealerPreOrdersNumApi'
import { checkPermission } from '@/utils/permissions'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'dealerPreOrdersNum',
  components: {
    DealerPreOrdersNumEditModal,
    AllocationStoreRuota,
    AllocationRecord
  },
  data() {
    return {
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      columnsDeatil: [
            {
            title: '预订单总数',
            dataIndex: 'total',
            key: 'total',
            /*width:150,*/
            align: 'center',
            ellipsis: true
            },
            {
            title: '首次申请总数',
            dataIndex: 'firstTotal',
            key: 'firstTotal',
            /*width:150,*/
            align: 'center',
            ellipsis: true
            },
            {
            title: '二次申请总数',
            dataIndex: 'secondTotal',
            key: 'secondTotal',
            /*width:150,*/
            align: 'center',
            ellipsis: true
            },
            {
            title: '固定配额',
            dataIndex: 'orderCount',
            key: 'orderCount',
            /*width:150,*/
            align: 'center',
            ellipsis: true
            },
             {
            title: '固定剩余配额',
            dataIndex: 'surplusPreOrderCount',
            key: 'surplusPreOrderCount',
            /*width:150,*/
            align: 'center',
            ellipsis: true
            },
            {
            title: '临时配额',
            dataIndex: 'temporaryQuota',
            key: 'temporaryQuota',
            /*width:150,*/
            align: 'center',
            ellipsis: true
            },
            {
            title: '临时剩余配额',
            dataIndex: 'temporaryRemainingQuota',
            key: 'temporaryRemainingQuota',
            /*width:150,*/
            align: 'center',
            ellipsis: true
            },
      ],
      tableDeatilData: [],
      isSubmit: checkPermission('dealer:quota:submit'),
      isApply: checkPermission('dealer:quota:apply'),
      isAudit: checkPermission('dealer:quota:audit'),
      isAllocation: checkPermission('dealer:quota:allocation'),
      isCheckAllocation: checkPermission('dealer:quota:check_allocation'),
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },

  filters: {
    status(type) {
      const typeMap = {
        0: '草稿',
        1: '待审核',
        2: '审核通过',
        3: '审核拒绝',
      }
      return typeMap[type]
    },
  },

  methods: {
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      this.selectedRowKeys = []
      this.selectedRows = []
      listDealerPreOrdersNum({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        dealerId: this.$store.getters.userInfo.dealerId,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
            // this.getDetail()
          }
        })
        .finally(() => (this.tableLoading = false))
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.pageSize = 10
      this.page.current = 1
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page = Object.assign({}, this.page)
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.DealerPreOrdersNumEditModal.setRowData({}, 'add')
        return
      } else if (name == 'store_allocation') {
        _this.$refs.AllocationStoreRuota.setRowData(_this.selectedRows[0], 'store_allocation')
        return
      }

      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delDealerPreOrdersNum(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'submit_audio':
          _this.$confirm({
            title: '确认提交审核？',
            onOk: () => {
              _this.axios
                .get(`/api/base/customer/dealerTemporaryQuotaRecord/submitReview/${_this.selectedRowKeys[0]}`)
                .then((res) => {
                  _this.$message.success(res.message)
                  _this.getData()
                })
                .catch((err) => {})
            },
          })
          break
        case 'dealerRollbackStatus':
          _this.$confirm({
            title: '撤回警告',
            content: '确认要撤回此条记录的提交审核吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await dealerRollbackStatus(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'check_allocation':
          _this.$refs.AllocationRecord.getList(_this.selectedRows[0], 'store')
          break
      }
    },
    //  // 配额详情
    // getDetail(){
    //   this.axios.get('/api/base/quota/dealerPreOrdersNum/selectByDealer').then(res=>{
      
    //   if(res.body){
    //    this.tableDeatilData = [res.body]
    //   }else{
    //     this.tableDeatilData = []
    //   }  
    //   console.log(this.tableDeatilData)
    // })
    // }
  },
  created() {
    this.getData()
    // this.getDetail()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
</style>
